.App {
  margin: 0;
  padding: 0%;
  display: flex;
}

#allTheWidth {
  margin: 0%;
  padding: 0%;
  width: 100vw;
  height: 100%;
}

.react-icons {
  vertical-align: middle;
}

.Login_Gradient {
  height: 100vh;
  margin: 0;
  background-image: url(../../theme/login_background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 50vw;
  background-size: 100vw;
  background-position: center;
  overflow: hidden;
}
.Login_appContainer {
  padding: 0%;
  padding-left: 0%;
  padding-right: 0%;
  margin: 0%;
  align-content: center;
  background-attachment: fixed;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  min-width: 300px;
  height: 100vh;
  box-sizing: border-box;
}

.Login_Gradient {
  height: 100vh;
  margin: 0;
  background-image: url(../../projects/fluxmed/assets/login_background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 50vw;
  background-size: 100vw;
  background-position: center;
  overflow: hidden;
}

.Login_dataContainer {
  display: flex;
  padding-top: 3rem;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  min-width: 300px;
  width: 100%;
  height: 100vh;
  min-height: 400px;
}

.fluxmed_Logo {
  margin-top: 10rem;
  width: 85%;
}

.login_GradientProject {
  height: 100vh;
  margin: 0;
  background-image: url(../../projects/fluxmed/assets/login_background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 50vw;
  background-size: 100vw;
  background-position: center;
  overflow: hidden;
}

.Login_form {
  font-size: 16px;
}

.Login_formContainer {
  padding-left: 10%;
  padding-right: 5%;
  margin: 2%;
  justify-content: center;
  display: flex;
}

.nopadding {
  padding: 0;
  margin: 0;
}

.Login_dataContainer {
  display: flex;
  padding-top: 3rem;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  min-width: 300px;
  width: 100%;
  height: 100vh;
  min-height: 400px;
}

.Login_logo {
  display: block;
  margin: 40px auto;
  max-height: 150px;
}

.Login_formContainerInp {
  padding-left: 2%;
  padding-right: 5%;
  margin: 2%;
  justify-content: center;
  display: flex;
}

.Login_loginButton {
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;

  border: 0;
  border-radius: 5px;
  background-color: var(--confirm-button-color);
  color: var(--font4-color);
  outline: 0;
  height: 4rem;
  width: 45%;
  min-width: 308px;
  margin-left: 1%;
}

.Login_errorMsg {
  color: var(--warning-color);
  font-family: Arial;
  text-align: center;
}

.Login_buttonArea {
  font-size: 20px;

  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 8em;
  width: 100%;
}

.Login_icons {
  color: var(--icon1-color);
  opacity: 0.8;
  padding-bottom: 2%;
  padding-left: 2%;
  align-content: center;
  align-self: center;
  align-content: center;
  min-width: 10%;
}
.Login_loginButton {
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;

  border: 0;
  border-radius: 5px;
  background-color: var(--confirm-button-color);
  color: var(--font4-color);
  outline: 0;
  height: 4rem;
  width: 30rem;
  margin-left: 1%;
}

.Login_loginButton:hover {
  color: var(--font2-color);
  background-color: var(--other-button-color);
  transition: 0.3s;
}

.Login_regularButton {
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;
  box-sizing: border-box;

  border: 0;
  background-color: transparent;
  color: var(--font2-color);
  outline: 0;
  width: 75%;
}

.Login_regularButton:hover {
  color: var(--font3-color);
  font-weight: bold;
}

.Login_govBrButton {
  height: 4rem;
  width: 50%;
}

.Login_govBrButton:hover {
  color: var(--font4-color);
  background-color: var(--gov2-bg-button-color);
  transition: 0.3s;
}

.Login_warning {
  background-color: transparent;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  height: 5%;
  border: 0;
  margin: 0;
  color: var(--warning-color);
}

#Login_appQr {
  width: 10vw;
}

.Login_divAppQr {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 99vh;
}

.Login_divAppQrCard {
  top: 88vh;
  position: absolute;
  border-radius: 15px;
  background-color: var(--qr-code-bg-color);
}

.Login_Row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Login_Column {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.container-fluid {
  padding: 0;
}

.Login_logoFlux {
  width: 20%;
  opacity: 1;
  margin-left: 2vh;
  align-self: center;
}

.login_divProject {
  display: flex;
  justify-content: center;
  position: flex;
  bottom: 0;
  margin-top: auto;
  left: -3vh;
}

@media screen and (max-width: 700px) {
  .Login_appContainer {
    height: 100vh;
  }

  .Login_divAppQrCard {
    width: 50vw;
    margin-bottom: 20px;
  }
}

@media (max-height: 700px) and (min-width: 1200px) {
  .Login_dataContainer {
    padding-top: 1rem;
  }
}
