.no-reports-container {
  margin: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

@media (max-width: 700px) {
  .no-reports-container {
    margin: 5%;
  }
}
