.customtab-underline {
    border-bottom: 4px solid #08824A;;
    width: fit-content;
    display: flex;
    padding-bottom: 0.4rem;
    padding-left: 0.5rem;
}

.customtab-title {
    align-self: flex-end;
    margin-bottom: 3px;
    margin-left: 4rem;
    margin-right: 6rem;
    color: #08824A;;
}

.customtab-section {
    padding: 2rem;
}