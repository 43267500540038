.patientSummary-container {
    /* border: 2px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin: 2% 0 2%;
    font-family: Arial;
    background-color: rgba(0,0,0,0.02);
}

.patientSummary-container .patientSummary-header {
    margin: 1% auto 1% auto;
    padding:  1% 2% 1% 2%;
    /* border: 0.5rem solid rgba(0, 0, 0, 0.2); */
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}