
.header-component {
    background-color: var(--accent-color-light);
    width: 100%;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    height: 9%;
  }

  .headerp {
    margin: 0;
    color:var(--accent-color-secondary);
    text-align: left;
    font-size: 1 rem;
  }

  .headerp_text {
    margin: 0;
    color: black;
    text-align: left;
    font-size: 1 rem;
  }
  .patientProfile {
     color:var(--accent-color-secondary);
    font-weight: bold;
    font-size: 22;
  }



