.floating-btn-back{
    position: fixed;
    /* position: -webkit-sticky; /*Safari */
    /* position: sticky; */
    z-index: 999;
    /* background-color: blue; */
    width: 100%;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    left: 48vw;
    top: 94vh;
}



@media(max-width: 700px) {

    .floating-btn-back{
        top: 88vh;   
        left: 42vw;
    }
}