.newSearchPatient-controlOptions {
    margin-top: 2.5vw;
    display: flex;
    justify-content: space-between;
}

.newSearchPatient-advancedSwitch {
    float: left;
}

.newSearchPatient-searchButton {
    float: right;
}

.newSearchPatient-section {
    padding: 2rem 5rem 2rem;
    border-bottom: 1px solid #bbb;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.newSearchPatient-fullHeight {
    min-height: calc(100% - 300px);
    border-bottom: 0;
}

.newSearchPatient-switchFieldname {
    font-size: 1.2vw;
}

.newSearchField-container .MuiInput-underline::before {
    /* border-bottom: none; */
    border: none;
    content: none;
}

.newSearchField-container .MuiInput-underline::after {
    content: none;
}

.newSearchField-container {
    width: 100%;
}

.newSearchField-searchbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.5vw;
    max-height: 54px;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(14px, 1.6vw, 28px);
    border-radius: 5px;
    padding-left: 1vw;
    padding-right: 1vw;
    /* border-radius: 40px; */
    border-style: solid;
    border-width: 1px;
    border-color: rgb(212, 212, 212);
    background-color: white;
    min-height: 30px;
    min-width: 350px;
}

.newSearchField-fieldname {
    font-size: 1.2rem;
}

.newSearchPatient-container .dropdownorganization-title {
    text-align: left;
    font-size: 1.2rem;
    font-weight: normal;
}

.dropdownorganization, .MuiFormControl-root, .MuiTextField-root {
    width: 100%;
}

.newSearchPatient-birthdateField {
    display: inline-block;
}

.MuiInput-underline::after {
    border-bottom: 2px solid #08824A !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: #08824A !important;
  }

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #08824A !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #08824A !important;
}

.newSearchPatient-outer table, th, td {
    border: 1px solid #bbb;
    padding: 0.5rem;
    padding-left: 1rem;
}

.newSearchPatient-outer table {
    width: 80%;
    font-size: 1rem;
}

.newSearchPatient-outer th {
    font-weight: bold;
}

.MuiPickersDay-daySelected {
    background-color: #08824A !important;
}