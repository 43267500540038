.MuiTimelineOppositeContent-root {
    min-width: 80px;
    max-width: 80px;
}

.timelineDrawer-upperArea .timelineDrawer-header {
    align-items: center;
    font-size: large;
    font-weight: bold;
    box-sizing: border-box;
    /* padding-top: 3%; */
    background-color: rgb(235, 235, 235);
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.infinity-container {
    background: none !important;
}

.timelineDrawer-upperArea {
    text-align: center;
    /* margin-bottom: 1%; */
}

.timelineDrawer-upperArea .timelineDrawer-filter {
    margin-bottom: 1%;
}

.timelineDrawer-upperArea .timelineDrawer-header button {
    position: absolute;
    right: 10px;
}
  