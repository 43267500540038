/* .Policy_ContaArea {
    display: flex;
    flex-direction: row;
} */

.policy-terms-title {
    font-size: 1.6rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.policy-terms-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5%;
}

.policy-terms-text {
  display: flex;
  justify-content: center;
  text-align: justify;
  font-size: 1.1rem;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.Policy_card {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 0;
    width: 100%;
    padding: 2rem;
    padding-left: 10%;
    padding-right: 10%;
    align-content: center;
    margin-bottom: 3%;
    align-self: center;
    font-weight: bold;
    background-color: var(--disabled-button-color);
}

.Policy_card:hover {
    opacity: 0.85;
    transition: 0.3s;
}

.Policy_card_info {
    flex: 1;
    font-weight: 200;
    font-size: 1.2rem;
    width: 100%;
    box-sizing: content-box;
    text-align: justify;
    padding-left: 10%;
    padding-right: 10%;
    color: var(--font5-color)
}

.accordion-terms {
    margin-top: 8%;
}

.Policy_title {
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-weight: 500;
    flex: 1;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    color: rgb(78, 78, 78);
    padding-bottom: 0;
}

.Policy_card_chosen {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 0;
    width: 100%;
    padding: 2rem;
    padding-left: 10%;
    padding-right: 10%;
    align-content: center;
    margin-bottom: 3%;
    align-self: center;
    font-weight: bold;
    background-color: var(--accent-color);
}

.Policy_card_chosen:hover {
    opacity: 0.85;
    transition: 0.3s;
}

.Policy_card_info_chosen {
    flex: 1;
    font-weight: 200;
    font-size: 1.2rem;
    box-sizing: content-box;
    text-align: justify;
    margin-left: 15%;
    margin-right: 15%;
    color: white;
}

.Policy_title_chosen {
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    flex: 1;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    color: white;
    padding-bottom: 0;
    box-sizing: border-box;
}

.Policy_h1 {
    /* color: rgb(0, 0, 0); */
    color: var(--font2-color);
    /* font-size: 30px; */
    font-size: 2.3rem;
    text-align: center;
    margin-bottom: 5%;
    box-sizing: content-box;
}

.Policy_info {
    flex: 1;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-size: 1.4rem;
    box-sizing: content-box;
    text-align: justify;
    margin-left: 0%;
    margin-right: 0%;
    color: rgb(0, 0, 0);
}

.Policy_content {
    padding: 2%;
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 2%;
    width: 80vw;
    background-color: var(--container-color);
    border: var(--border-container);
    border-radius: var(--border-radius-container);
}

.Policy_appContainer {
    display: flex;
    padding: 1%;
    padding-bottom: 0;
    align-content: center;
    background-image: url(../../theme/login_background.jpg);
    background-color: 'black';
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
}

@media(max-width: 700px) {
    .Policy_content {
        padding-left: 12%;
        padding-right: 12%;
        padding: 12%;
    }
    .Policy_card {
        margin-top: 10%;
        padding-right: 4%;
        padding-left: 4%;
    }
    .Policy_card_chosen {
        margin-top: 10%;
        padding-right: 4%;
        padding-left: 4%;
    }
}

.policyPage-container {
    width: 100%;
    padding: 8%;
    min-height: 90vh;
}

.policyPage-Loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25vh;
}