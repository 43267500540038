.schedulerContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.schedulerContent .schedulerBody {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  margin-top: 40px;
}

 .schedulerContent .schedulerBody .schedulerButton {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: none;
  padding: none;
  height: 500px;
  /* height: fit-content; */
  /* border: 1px solid red; */
}
 
.weekDayContent .card {
  /* border: 1px solid red; */
  overflow: scroll;
  padding: none;
  margin: none;
  display: flex;
  height: 500px;
}
  
.weekDayContent .timeButtons {
  flex-direction: column;
}