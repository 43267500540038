.no-content-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: black; */
    margin-bottom: 10%;
}

.icon-no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10%;
    /* background-color: white; */
}

.no-content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}