.newConsentsPage-section {
    padding: 2rem 5rem 2rem;
    border-bottom: 1px solid #bbb;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.newConsentsPage-section table, th, td {
    border: 1px solid #bbb;
    padding: 0.5rem;
    padding-left: 1rem;
}

.newConsentsPage-section table {
    width: 80%;
    font-size: 1rem;
}

.newConsentsPage-section th {
    font-weight: bold;
}


.newConsentsPage-section tr > td:last-of-type {
    width: 1rem;
    padding-left: 0.5rem;
 }

 .newConsentsPage-table {
    margin-top: 1rem;
 }
