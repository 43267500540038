
.SignUpDivText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

.SignUpDivText h6 {
  font-size: 1.2rem;
}

h6 {
  color: var(--font5-color);
  width: 60%;
  min-width: 5cm;
  text-align: justify;
}

.SignUpRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}

.SignUpColumn {
  display: flex;
  flex-direction: column;
}

.SignUpAppContainer {
  display: flex;
  justify-content: center;
  padding: 0%;
  padding-bottom: 0;
  align-content: center;
  background-image: url(../../theme/login_background.jpg);
  background-size: auto;
  background-attachment: fixed;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  min-width: 300px;
  box-sizing: border-box;
  overflow: hidden;
}

::webkit-scrollbar {
  display: block;
}
.SignUpDataContainer {
  display: flex;
  justify-content: center;
  margin: 5% 20% 5% 20%;
  padding: 5%;
  align-content: center;
  border: 2px rgb(255, 255, 255);
  border-radius: 12px;

  box-sizing: border-box;
  background-image: linear-gradient(135deg, white, rgb(231, 231, 231));
  min-width: 300px;
  min-height: 500px;
  height: 98vh;
  overflow: auto;
}

.SignUpLogo {
  width: 12vw;
  min-width: 6cm;
  
}

.SignUpInput {
  /* background-color: var(--accent-color-secondary); */
  font-size: 1.4rem;
  height: 3vh;
  width: 26vw;
  min-width: 5cm;
  margin-left: 1vw;
  margin-right: 1vw;
  min-height: 1.2cm;
  box-sizing: border-box;
  margin-top: 1vh;
  border: solid 1px lightgray;
  border-radius: 5px;
  /* border-width: 0; */
  text-align: center;
}

.SignUpInput:hover {
  background-color: rgb(245, 255, 245);
  color: black;
  /* font-weight: bold; */
  transition: 0.3s;
}

select {
  padding-left: 2vw;
  /* padding-right: 2vw; */
}

.SignUpIcons {
  margin-top: 1vw;
  height: 6vh;
  width: 2vw;
  min-width: 0.5cm;
  min-height: 1.2;
  margin-right: 1vw;
  /* background-color: thistle; */
}

.SignUpCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.SignUpButton {
  background-color: var(--accent-color);
  color: var(--font4-color);
}

.SignUpButtonDiv {
  display: flex;
  width: auto;
  justify-content: center;
}
.SignUpButtonDiv2 {
  display: flex;
  width: 96%;
  min-width: 6.4cm;
  justify-content: center;
  margin: 2%;
}

.SignUpLink {
  font-size: 1.4rem;
  font-weight: bold;
  opacity: 1;
  margin-bottom: 1%;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;
  
  border: 0;
  background-color: transparent;
  color: var(--font2-color);
  outline: 0;
  width: 60%;
}

@media(max-width: 700px) {
  .SignUpDataContainer {
    /* padding-left: 1%;
    padding-right: 1%; */
    margin: 5% 8% 5% 8%;
  }
}