.RecoverPass_appContainer {
    padding: 0%;
    padding-bottom: 0;
    align-content: center;
    background-image: url(../../theme/login_background.jpg);
    background-color: 'black';
    background-size: auto;
    background-attachment: fixed;
    height: 100vh;
    /* width: 90vw; */
    width: inherit;
    /* box-sizing: border-box; */
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    box-sizing: border-box;

    overflow: hidden;

    
    min-width: 300px;
    min-height: 640px;
  }

  .RecoverPass_dataContainer {
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    /* padding-top: 20%; */
    padding: 10%;

    align-items: center;
    align-self: center;
    text-align: center;
    align-content: center;
    box-sizing: border-box;



    background-color: rgba(255,255,255,0.7);
    border: 2px rgba(255,255,255,0.5);

    
    /* min-width: 300px; */
    /* min-height: 640px; */
  }

  .RecoverPass_col {
    display: flex;
    flex-direction: column;
  }

  .RecoverPass_inputContainer {
    opacity: 1;

    margin-top: 2%;
    align-content: center;
    align-self: center;
    align-content: center;
    text-align: center;
    box-sizing: border-box;
    
    /* border: 1; */
    height: 3.5vw;
     /* width: 60%; */ 
    /* color: var(--font2-color); */
    /* font-size: 1.4rem; */
    font-weight: bold;
    width: 100%;
    text-align: center;
    /* border-style: solid; */
    /* border-color: gray; */
    /* border-radius: 40px; */
    /* background-color: var(--border-color); */
  }

  .RecoverPass_Button {
    font-size: 2rem;
    /* display: inline-block; */
    margin: 4%;
    margin-bottom: 2%;
    padding: 3%;
    text-align: center;
    align-content: center;
    align-self: center;
    align-content: center;
    box-sizing: border-box;
    
    border: 0;
    color: white;
    outline: 0;
    height: 30%;
    min-height: 50px;
    width: 50%;
  }

  
  .RecoverPass_errorMsg {
    font-size: clamp(14px, 1.6vw, 24px);
    margin:5%;
    color: red;
  }

  
  .RecoverPass_successMsg {
    font-size: clamp(14px, 1.6vw, 24px);
    margin:5%;
     color: var(--font2-color);
  }
  
  .RecoverPass_title {
    font-size: clamp(24px, 1.8vw, 36px);
    margin:5%;
     color: var(--font2-color);
  }
  
  .RecoverPass_text {
    font-size: clamp(14px, 1.4vw, 24px);
    margin:5%;
    color: rgb(66, 66, 66) 59, 58);
  }

  .RecoverPass_callback {
    text-align: center;
    align-content: center;
    align-self: center;
    align-content: center;
    box-sizing: border-box;
  }
