.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .side_bar{
    position: fixed;
    /* display: block; */
    top: 0;
    bottom: 0;
    left: 0;
    width: 7rem;
    
    border-right: 1px solid #bbb;
    overflow-x: scroll;
    z-index: 1051;
    /* z-index: 1; */
    color: #fff;
    /* background-color: #fff; */
    font-weight: 200;
    background-size: cover;
    transition: 0.15s;
    /* background-position: 50%; */
  }
  
  .logoFlux {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 60px;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
  }
  
  .main_panel_sb {
    background: rgba(203, 203, 210, 0.15);
    position: relative;
    float: right;
    margin: 0;
    width: calc(100vw - 7rem);
    margin-left: 7rem;
    /* display: flex; */
  
    transition: 0.15s;
  }
  
  .bottom_bar {
    display: none;
    z-index: 1051;
  }
  
  .logoBottom {
    margin-left: 5%;
    height: 40px;
    width: 40px;
  }

  .ui.icon.menu .item {
    color: #48494B;
  }

  .ui.icon.menu .item:hover {
    color: #08824A;
  }

  .ui.icon.menu .active.item {
    color: #08824A;
  }

  .ui.secondary.menu .active.item {
    background: none;
  }


  @media (max-width: 1200px) {
  
    .side_bar {
      width: 7rem;
      z-index: 1051;
    }
  
  }
  
  @media (max-width: 700px) {
  
  
    .side_bar {
      display: none;
    }
  
    .bottom_bar {
      display: block;
      position: fixed;
      bottom: 0;
  
      z-index: 1051;
  
      align-items: center;
      width: 100%;
  
      background-color: white;
      height: 40px;
    }
  }
  