.consentsPage-container {
    width: 100%;
    padding: 2%;
    min-height: 90vh;
}

.consent-line{
    background-color: white;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* padding-left: 5%;
    padding-right: 5%; */
}

.consent-col {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 2rem 0 2rem;

}

@media (max-width: 950px) {
    .consent-col {
        margin: 0 1rem 0 1rem;
    }
}



ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

.consentSearchBox {
    
    align-content: center;
    align-self: center;
    box-sizing: border-box;
    
    font-size: 1.2rem;
    width: 60vw;
    height: 3.5vw;
    text-align: center;
    border-radius: 0.8rem;
    border-style: solid;
    border-width: 1px;
    border-color: var(--searchbar-border-color);
    min-height: 3rem;
    margin-top: 2%;
    margin-bottom: 3%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list {
    list-style-type:none;
    margin-left: 0;
}

.consentItemsContainer {
    width: 100%;
    display: flex;
    padding: 0;
    justify-content: center;
}

.consentCategory {
    color: var(--font2-color);
    font-size: 1.6rem;
    padding-bottom: 2%;
    font-weight: bold;
    text-align: center;
}

#consentModalConfirmButton {
    background-color: var(--cancel-button-color);
    border-color: var(--cancel-button-color);
}

#consentModalConfirmButtonGive {
    background-color: var(--confirm-button-color);
    border-color: var(--confirm-button-color);
}

.consentColumn {
    display: flex;
    padding-left: 0;
    justify-content: space-around;
    /* align-self: center; */
    overflow: hidden;
    width: 100%;
}

.consentGiveButtonDiv {
    display: flex;
    align-self: flex-end;
    box-sizing: border-box;
    margin-right: 2vw;
}

.containers {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    padding-left: 2%;
    padding-top: 2%;
    justify-content: center;
}

.human-entry-input {
    display: flex;
    padding: 1%;
    justify-content: center;
    margin: 1%;
}

.state-city-drop {
    display: flex;
    padding: 1%;
    justify-content: space-between;
    margin: 1%;
}

.container-main-info {
    
    /* margin-left: 2%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 1%; */
    /* margin: 2%; */
    /* background-color: white; */
}


.container-side-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 1%; */

    /* max-width: 100%; */
    /* height: fit-content; */
    background-color: white;
    /* border:  1px solid whitesmoke; */
}

.consent-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    font-size: 1.6rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.consent-modal-text {
    display: flex;
    justify-content: center;
    text-align: justify;
    font-size: 1.4rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    
}

.container-hidden {
    display: none;
}

.consent-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}



.consent-new-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}
.consent-access-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* writing-mode: vertical-rl; */
    /* text-orientation: mixed; */
    padding: 0;
    margin-left: 0;
}

.lateral-button{
    margin-left: 4%;
    margin-top: 1%;
    display: flex;
}

#history-title {
    font-size: 1.8rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    margin-bottom: 2%;
    margin-top: 2%;
}


#consentGiveButton{
    display: inherit;
    width: 4vw;
    height: 4vw;
    min-width: 30px;
    min-height: 30px;
    color: var(--accent-color);
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border-style: none;
    border-width: 0px;
    background-color: var(--other-button-color);
}

#consentGiveButton:hover {
    background-color: var(--confirm-button-color);
    color: var(--font4-color);
    transition: 0.3s;
    width: 5vw;
    height: 5vw;
}

.consentGiveConsentInput{
    width: 100%;
    height: 3rem;
    display: flex;
    /* border: solid; */
    /* border-color: var(--searchbar-border-color); */
    border: 0;
    text-align: center;
}

@media(max-width: 700px) {
    .consentDataContainer{
        padding-left: 0;
        padding-right: 0;
        width: 94%;
    }
    
    .consentPage {
        padding: 1%;
    }
}

@media(max-width: 1000px) {
    .consentDataContainer{
        width: 88%;
    }
}

.consentsPage-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
}

/* New ConsentsPage */

.consents-tab {
    padding: 0 !important;

    .consents-table-title {
        h3 {
            text-decoration: none;
        }

        &:hover {
            h3 {
                text-decoration: underline;
            }
        }

        i {
            transform: rotate(180deg);
            transition: .2s;
        }
    }
    .Mui-expanded {
        i {
            transform: rotate(0);
            transition: .2s;
        }
    }
}

.consentsPage-container .MuiTableRow-hover {
    cursor: pointer;
}

.consents-accordion {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.back-btn, .cancel-btn {
    border: none;
    background: none;
    width: fit-content;
    height: fit-content;
    transition: .2s;
}

.cancel-btn:hover {
    opacity: 0.7;
}

.back-btn:hover {
    color: #08824A;
    transition: .2s;
}

.search-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 32px !important;
    opacity: .5;
    transition: .3s !important;
}

.search .input input:focus + .search-icon {
    opacity: 1;
    transition: .3s !important;
}