.timeline-subject-name {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  width: 100%;
}

.timeline-subject-description {
  font-size: 15px;
  margin-top: 0;
  white-space: nowrap;
}

.reports-container {
  overflow-y: scroll;
  height: 100%;
  background-color: white;
  padding: 2%;
  margin-top: 5%;
}
.year-title {
  background-color: var(--timeline-background-color);
  font-weight: 'bold';
  text-align: 'center';
  padding: '2%';
  color: var(--font1Color);
  margin-bottom: 0;

  width: 100%;
  margin-top: 0;
}

.year-title-full-size-title {
  color: #fff; 
  background-color: #08824ADD;
  margin-left: 8%;
  width: 87.5%;
}

.year-title-middle-size-title {
  color: #fff; 
  background-color: #08824ADD;
  margin-left: 16.5%;
  width: 82.5%;
}

span.MuiTimelineDot-defaultGrey {
  background-color: #08824ADD !important;
  position: absolute !important;
  top: 35% !important;
  left: 7.7% !important;
  z-index: 1 !important;
}
span.MuiTimelineDot-defaultGrey-pdfOpen-ajust {
  background-color: #08824ADD !important;
  position: absolute !important;
  top: 35% !important;
  left: 7.3% !important;
  z-index: 1 !important;
}

p.MuiTypography-colorTextSecondary {
  color: #08824ADD !important;
  position: absolute !important;
  top: 25% !important;
}
p.MuiTypography-colorTextSecondary-pdfOpen-ajust {
  color: #08824ADD !important;
  position: absolute !important;
  top: 25% !important;
  left: 10% !important;
}

span.MuiTimelineConnector-root {
  position: absolute !important;
  left: 8.1% !important;
  height: 190px !important;
  z-index: 0 !important;
}

li.MuiTimelineItem-root {
  border-bottom: none !important;
}