.DeployVersion_HeaderBottomLateral {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 2%;
  display: flex;
  justify-content: center;
  color: black;
}

.DeployVersion_Login {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-right: 2%;
  display: flex;
  justify-content: right;
}
