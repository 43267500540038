.pagemask-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  background-image: url(../../theme/login_background.jpg);
  background-color: rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

.pagemask-toolbar {
  justify-content: space-between;
}

.pagemask-container-fluxmed {
  background-image: url(../../projects/fluxmed/assets/login_background.jpg);
}

.pagemask-content {
  width: calc(100% - 4%);
  margin: 3%;
  padding: 1%;
  box-sizing: border-box;
  /* border: 1px solid red; */
  min-height: 70vh;
  background: white;
}

.pagemask-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refresh-token-input {
  width: 100%;
  border: #000 solid 0.1em;
  border-radius: 0.2em;
  margin-bottom: 1em;
  padding: 0.2em;
}
