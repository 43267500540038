.newPatientList-totalPatients {
    color: #08824A;
    margin-bottom: 1.5rem;
}

.newPatientList-container tr > td:last-of-type {
    width: 1rem;
    padding-left: 0.5rem;
 }

.newPatientList-container {
    margin-bottom: 3rem;
}