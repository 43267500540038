.header-togglebtn {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: blue; */
    /* padding: 2%; */
    width: 100%;
}

.header-row {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2% 0% 2% 0%;
    width: 100%;
}


.showchild {
    display: flex;
    justify-content: space-around;
    margin-left: 5%;
    margin-right: 5;
    /* background-color: red; */
}

.showchild:last-of-type {
    margin-left: 10%;
    margin-right: 5%;

    /* background-color: blue; */
}



.bellow-deck {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    transition: 0.3;
    /* color: white; */
    background-color: whitesmoke;
    /* background-color:#6c757d; */
    width: 100%;
    height: fit-content;
    padding-right: 30%;
    margin: 0;
    text-align: justify;
    padding: 1% 0 1% 0;
    font-size: 1.2rem;

}

.deck-left{
    display: flex;
    flex-direction: column; 

}


.show-green {
    color: var(--accent-color);
}

.showname-btn-name {
    
    /* justify-content: center; */
    display: flex;
    opacity: 1;
    /* padding-left: 25%; */
    /* padding-right: 20%; */

    text-align: justify;

    display: flex;
    flex-direction: row;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.showname-btn-text-none {
    margin-left: 1%;
    margin-right: 1%;
    /* background-color: green; */

    display: none;
    flex-direction: row;
    width: fit-content;
    font-size: 1.2rem;
    
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}