.error-input-message {
    color: rgb(255, 77, 77);
    font-size: small;
}
.input-error {
    border-color: red!important;
    border-width: 1px!important;
}
.form-input-container {
    margin-bottom: 12px;
}