/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, p, a,
b, u, i, center,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.btn-success {
  color: #fff;
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
}

.btn {
  border-radius: 0 !important;
}

.btn-link {
  color: var(--accent-color) !important;
}

.btn-outline-success {
  color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem transparent;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff !important;
  background-color: var(--accent-color) !important;
}

.btn-outline-success:hover {
  color: white !important;
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
}

.container {
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  padding: 0%;
  margin: 0%;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  align-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  outline-width: 0px;
}

.accent-color-secondary {
  color: var(--accent-color-secondary);
}

.accent-color {
  color: var(--accent-color) !important;
}

.accent-color-light {
  color: var(--accent-color-light);
}

:root {
  font-size: 85%;

  /* Colors */
  --border-color: whitesmoke;
  --container-color: rgb(255, 255, 255);
  --timeline-background-color: rgb(255, 255, 255);
  --accent-color: #08824a;
  --accent-color-secondary: rgb(52, 42, 99);
  --accent-color-light: #b9f1f7;
  --secondary-color: rgba(255, 255, 255, 0.664);
  --bg-main-color: rgb(253, 253, 253);

  --cancel-button-color: #820808;
  --confirm-button-color: #08824a;
  --other-button-color: white;
  --disabled-button-color: rgba(172, 172, 172, 0.4);
  --gov-bg-button-color: white;
  --gov2-bg-button-color: #1351b4;
  --gov-border-button-color: #1351b4;

  --searchbar-border-color: lightgray;

  --font1-color: rgb(45, 62, 80);
  /* --font2-color: #8208EE; */
  --font2-color: #08824a;
  --font3-color: #006421;
  --font4-color: white;
  --font5-color: rgb(100, 100, 100);
  --font6-color: #1339b4;

  --warning-color: red;

  --border-container: 3px var(--border-color) solid;
  --border-radius-container: 0;

  --icon1-color: gray;

  --qr-code-bg-color: rgb(212, 212, 212);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

html,
body,
#root {
  height: 100vh;
}

p {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.modal-footer,
.modal-body {
  font-size: 1.2rem;
}

.modal-title {
  font-size: 1.4rem;
}

.ui.input {
  border-radius: 0% !important;
}

.field {
  /* background-color: blue; */
  /* padding: 0%!important; */
  margin: 0% !important;
}

.ui.input {
  width: 100%;
  padding: 0% !important;
  margin-left: 0% !important;
  margin-right: 0% !important;

  margin-bottom: 2%;
}

.dropdown-gen {
  color: #08824a;
}

body,
input,
button,
textarea {
  font: 500 1.4rem sans-serif;
  border-radius: 0% !important;
}

input[type='date']:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type='date']:focus:before,
input[type='date']:valid:before {
  content: '';
}

@media (max-width: 700px) {
  :root {
    font-size: 65%;
  }
}

label {
  font-size: 1.1rem;
  margin: 0;
}

.pages-container {
  width: calc(100vw - 7rem);
  /* border: 4px solid red; */
  margin-left: 7rem;
  height: 100vh;
}

@media (max-width: 700px) {
  .pages-container {
    max-width: 100vw;
    width: 100vw;
    height: calc(100vh - 40px);
    /* border: 4px solid blue; */
    margin-left: 0;
  }
}
