@import url("https://fonts.googleapis.com/css?family=Lato");

.tab {
  width: 100%;
  padding: 25px;
  font-family: sans-serif;
  color: #444;
}

ul.inline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

ul.inline,
li {
  display: inline-block;
  margin-left: 0;
  padding: 10px;
  border-bottom: 2px solid #eee;
  transition: all 0.5s;
  font-family: Lato, sans-serif;
  font-weight: 300;
  cursor: pointer;
  /* color: #aaa; */
}

ul.inline,
li.selected {
  border-bottom: 2px solid #08824ADD;
  color: #444;
}