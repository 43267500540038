.newpagemask-section {
    padding: 2rem 5rem 2rem;
    border-bottom: 1px solid #bbb;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.newpagemask-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.newpagemask-welcome {
    font-size: 1.8rem;
    color: #08824A;
    margin-left: 2rem;
}

.newpagemask-dropdowns {
    display: flex;    
}
