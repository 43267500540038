.ui.search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.input {
    width: 80%;
}

.ui.search>.results {
    width: 80% !important;
    left: 10%;
    right: 10%;
}
.searchContent-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    /* padding: 0% 5%; */
}

#searchBox {
    display: flex;
    /* width: 80vw; */
    /* background-color: blue; */
}
