#label_menu {
  font-size: small;
  text-align: left;
}

#navbar {
  background-color: var(--border-color);
  display: flex;
  top: 0;

  z-index: 1051;
  width: 100vw;
  height: 8%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  margin-right: 5%;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  #navbarOptions {
    width: 70vw;
  }
}

/* Navbar links */
#navbar a {
  color: rgb(71, 71, 71);
  float: left;
  display: block;
  text-align: center;

  padding: 14px;
  padding-bottom: 3%;
  font-weight: bold;
  box-sizing: border-box;
  text-decoration: none;
}

#navbarExternalDiv {
  display: flex;
  flex-direction: column;
}

#navbarButton {
  background-color: white;
  border-width: 0;
  outline-width: 0px;
}

#navbar a:last-child {
  background-color: var(--border-color);
  float: right;
}

#navbar a:hover {
  color: var(--font2-color);
  background-color: rgb(221, 221, 221);
}

.menu {
  cursor: pointer;
}

.item:hover {
  background-color: lightgrey;
}

.Header_Logo {
  height: 30px;
  margin-left: 30px;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  min-width: 70px;
  min-height: 50px;
  padding: 1%;
}

.Header_Logo_sm_sb {
  /* height: 30px; */
  display: flex;
  flex: 1;
  max-width: 7rem;
  padding: 10px;
}

.header_centralize {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: blue;
}

.no_margin {
  margin: 0;
}

.no_padding {
  padding: 0;
}

.principalHeaderText {
  text-transform: unset !important;
}
