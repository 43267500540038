div::-webkit-scrollbar {
  display: none;
}

.Button:hover {
  background-color: rgb(24, 68, 35);
}

.Timeline:hover {
  background-color: rgb(148, 148, 148);
  color: white;
}

.Index_Button:hover {
  background-color: white;
  color: #08824a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

#Visualizer_level4Label {
  border: 0;
  /* background-color: black; */
  /* border-radius: 2%; */

  padding: 2%;
  font-size: 15;
  font-weight: bold;
  margin-top: 10px;
  /* margin-bottom: 4; */
  width: 100%;
  box-sizing: border-box;
}

#Visualizer_detail_container {
  background-color: var(--border-color);
  padding: 0;
  /* margin-top: 4%; */
  color: var(--font2-color);
  /* height: 3vw; */
}

#Visualizer_detail {
  /* display: none; */
  height: 0vw;
  /* background-color: rgb(4, 47, 27); */
  color: white;
  opacity: 0;

  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.modal-90w {
  width: 90vw;
}

.Visualizer_header {
  /* overflow: hidden; */

  box-sizing: border-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;

  padding-top: 0.5%;
  background-color: #ffffff;
  width: 100%;
  /* height: 20vw; */
  /* max-height: 120px; */
  padding-bottom: 0.5%;
  /* margin-bottom: 1%;     */
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.Visualizer_sidebar {
  height: 900px;
  /* width: 32vw; */

  /* overflow-y: scroll; */
  opacity: 0.8;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;
  /* margin-left: 5%; */
  /* padding-left: 1%; */
  /* padding-right: 1%; */
  /* margin-right: 5%; */
  /* border-radius: 5px; */
  /* position: 'fixed'; */
  /* background-color: var(--accent-color-secondary); */
  width: 100%;
  background-color: rgb(235, 235, 235);
  min-width: 180px;
  /* background-color:rgb(191, 236, 215); */
}

.Visualizer_DocTypeButton {
  box-sizing: border-box;
  background-color: white;
  height: 7vw;
  width: 100%;
  padding: 2%;
  /* color:rgb(108, 202, 158); */
  color: #08824a;
  font-size: 1.5vw;
  border: 1;
  vertical-align: center;
  border-width: 5px;
  border-color: red;
  cursor: pointer;
}

.Visualizer_DocTypeButton:hover {
  background-color: rgb(8, 131, 74);
  color: white;
}

.Visualizer_DocTypeButton:focus {
  transition: 0.3s;
  background-color: rgb(7, 109, 62);
  color: white;
}

.Visualizer_DocTypeButton:active {
  transition: 0.3s;
  background-color: rgb(7, 109, 62);
  /* background-color: rgb(3, 46, 26); */
  color: white;
}

.Visualizer_row {
  display: flex;
  flex-direction: row;
  /* min-height: 20vh; */
  /* min-width: 90vw; */
  /* flex-wrap: wrap; */
}

.Visualizer_column {
  display: flex;
  flex-direction: column;
}

.Visualizer_documentViewer_patientSumm {
  opacity: 0.9;
  padding-left: 2%;
  padding-right: 2%;
  box-sizing: border-box;
  align-self: center;
  align-content: center;
  align-items: center;
}

.Visualizer_documentViewer {
  opacity: 0.9;
  margin-left: 5%;
  margin-right: 5%;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;

  height: 100%;
  width: 80vw;
  border: 0;
  border-style: solid;
  /* border-radius: 12px; */
}

.Visualizer_documentViewerNotSelected {
  background-color: white;
  opacity: 0.8;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
  height: 80%;
  border: 0;
  border-style: solid;
  /* border-radius: 12px; */
}

.Visualizer_documentViewerError {
  background-color: white;
  opacity: 0.8;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
  height: 80%;
  border: 0;
  border-style: solid;
  /* border-radius: 12px; */
}

/* .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
} */

.VisualizerPage {
  /* background-color: rgba(202, 202, 202, 0.9); */
  background-color: white;
  align-content: start;
  /* border: 2px rgba(255,255,255,0.5); */
  /* border-radius: 12px; */
  /* border: 2px solid red; */
  border-top: 1px solid rgba(202, 202, 202, 0.9);
  box-sizing: border-box;
  height: 100%;
  min-width: 300px;
  min-height: 640px;
  /* padding: 1%; */
  /* padding-top: 2%; */
  width: 100%;
  /* margin-left: 5%; */
  /* margin-right: 5%; */

  /* min-height: 100vh; */
  /* width: inherit; */
}

#Visualizer_modalDoc {
  width: 65vw;
  max-width: none !important;
  min-width: 590px;
}
/* @media screen and (max-width: 600px) {
    #Visualizer_modalDoc {
      width: 90vw;
      max-width: none!important;
    }
  } */
#Visualizer_modalDoc {
  width: 80vw;
}

@media screen and (max-width: 600px) {
  .Visualizer_header {
    padding-top: 20px;
    background-color: #ffffff;
    width: 100%;
    /* height: 20vw; */
    /* max-height: 100px; */
    padding-bottom: 0.5%;
    /* margin-bottom: 1%;     */
    overflow: hidden;
    /* text-overflow: ellipsis; */
  }
}

.loadingDiv {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25vh;
}

.ExamButton {
  margin-right: 5px;
  border: 0px solid #08824ADD;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  padding: 5px;
}

.ExamButton-enabled {
  background-color: #08824ADD !important;
  color: #fff !important;
}

.ExamButton-disabled { 
  background-color: #e0e0e0 !important;
  color: black !important;
}

.ExamButton:hover {
  cursor: pointer;
}

.normal-exam-results {
  width: 100%;
}

.pdf-viwer-enabled {
  display: block;
  width: 50%;
  min-height: 500px;
}
.pdf-viwer-disabled {
  display: none;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  position: initial;
  float: right;
}