.profile-conta-area {
    display: flex;
    justify-content: space-between;
}

p {
    padding-top: 0.7rem!important;
    padding-bottom: 0.7rem!important;
}

.profile-edit-button {
    margin-bottom: 2%;
    text-align: center;
    align-content: center;
    
    border: 0;
    border-radius: 5px;
    background-color: var(--accent-color-secondary);
    color: white;
    outline: 0;
    height: 30%;
    min-height: 40px;
    min-width: 180px;
}

.profile-edit-button:hover {
    color: var(--font2-color);
    background-color: var(--border-color);
    height: 33%;
    transition: 0.3s;
}

.profile-password-button {
    margin-bottom: 2%;
    text-align: center;
    align-content: center;
    border: 0;
    border-radius: 5px;
    background-color: var(--accent-color-secondary);
    color: white;
    outline: 0;
    height: 30%;
    min-height: 40px;
    width: 20%;

}

.profile-centered-buttons {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}




.profile-box {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
}

.profile-h1 {
     color: var(--font2-color);
    font-size: 2rem;
}
.profile-title {
    font-weight: 400;
    flex: 1;
    color:black;
    font-size: 1.4rem;
    /* padding-bottom: 0; */
}

.profile-info {
    
    font-weight: 400;
    flex: 1;
    font-size: 1.4rem;
    color: black;
}

.profile-info-edit {
    flex: 1;
    font-size: 1.4rem;
     color: var(--font2-color);
    background-color: var(--border-color);
    border-radius: 30px;
    text-align: center;
    border: 0;

}

.profile-content {
    padding: 2%;
    padding-left: 10%;
    padding-right: 10%;
    /* max-height: fit-content; */
    width: 80vw;
    
    /* overflow:auto; */
    
    background-color: var(--container-color);
    border: var(--border-container);
    /* border-radius: 12px; */
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    min-height:fit-content;
    /* flex-grow: 1; */
}

.profile-app-container {
    display: flex;
    justify-content: center;
    padding: 1%;
    padding-bottom: 2%;
    align-content: center;
    background-image: url(../../theme/login_background.jpg);
    
    background-size: cover;
    background-attachment: fixed;
    /* height: 100vh; */
    /* width: auto; */
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    
  }

  .profilePage-container {
      width: 100%;
      padding: 2%;
  }

  @media(max-width: 700px) {
      .profile-content {
          padding: 5%;
      }
  }

  .profilePage-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
  }