@import url(https://fonts.googleapis.com/css?family=Lato);

.SignUpDivText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2%;
}

.SignUpDivText h6 {
  font-size: 1.2rem;
}

h6 {
  color: var(--font5-color);
  width: 60%;
  min-width: 5cm;
  text-align: justify;
}

.SignUpRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-self: center;
  align-items: center;
}

.SignUpColumn {
  display: flex;
  flex-direction: column;
}

.SignUpAppContainer {
  display: flex;
  justify-content: center;
  padding: 0%;
  padding-bottom: 0;
  align-content: center;
  background-image: url(../../static/media/login_background.892c4479.jpg);
  background-size: auto;
  background-attachment: fixed;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
  min-width: 300px;
  box-sizing: border-box;
  overflow: hidden;
}

::webkit-scrollbar {
  display: block;
}
.SignUpDataContainer {
  display: flex;
  justify-content: center;
  margin: 5% 20% 5% 20%;
  padding: 5%;
  align-content: center;
  border: 2px rgb(255, 255, 255);
  border-radius: 12px;

  box-sizing: border-box;
  background-image: linear-gradient(135deg, white, rgb(231, 231, 231));
  min-width: 300px;
  min-height: 500px;
  height: 98vh;
  overflow: auto;
}

.SignUpLogo {
  width: 12vw;
  min-width: 6cm;
  
}

.SignUpInput {
  /* background-color: var(--accent-color-secondary); */
  font-size: 1.4rem;
  height: 3vh;
  width: 26vw;
  min-width: 5cm;
  margin-left: 1vw;
  margin-right: 1vw;
  min-height: 1.2cm;
  box-sizing: border-box;
  margin-top: 1vh;
  border: solid 1px lightgray;
  border-radius: 5px;
  /* border-width: 0; */
  text-align: center;
}

.SignUpInput:hover {
  background-color: rgb(245, 255, 245);
  color: black;
  /* font-weight: bold; */
  transition: 0.3s;
}

select {
  padding-left: 2vw;
  /* padding-right: 2vw; */
}

.SignUpIcons {
  margin-top: 1vw;
  height: 6vh;
  width: 2vw;
  min-width: 0.5cm;
  min-height: 1.2;
  margin-right: 1vw;
  /* background-color: thistle; */
}

.SignUpCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.SignUpButton {
  background-color: var(--accent-color);
  color: var(--font4-color);
}

.SignUpButtonDiv {
  display: flex;
  width: auto;
  justify-content: center;
}
.SignUpButtonDiv2 {
  display: flex;
  width: 96%;
  min-width: 6.4cm;
  justify-content: center;
  margin: 2%;
}

.SignUpLink {
  font-size: 1.4rem;
  font-weight: bold;
  opacity: 1;
  margin-bottom: 1%;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;
  
  border: 0;
  background-color: transparent;
  color: var(--font2-color);
  outline: 0;
  width: 60%;
}

@media(max-width: 700px) {
  .SignUpDataContainer {
    /* padding-left: 1%;
    padding-right: 1%; */
    margin: 5% 8% 5% 8%;
  }
}
.AppQrCodeDiv {
    display: flex;
    width: 220px;
    height: 80px;
    margin: 10px;
    font-size: 1.125rem;
    color: rgb(1, 71, 1);
}

.floating-btn-back{
    position: fixed;
    /* position: -webkit-sticky; /*Safari */
    /* position: sticky; */
    z-index: 999;
    /* background-color: blue; */
    width: 100%;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    left: 48vw;
    top: 94vh;
}



@media(max-width: 700px) {

    .floating-btn-back{
        top: 88vh;   
        left: 42vw;
    }
}
.city-group-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.err-boundary-scr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    font-style: normal;
    font-size: 2rem;
    color: var(--accent-color-secondary);
    /* background-color: black; */
}

#err-boundary-subtitle {
    color: grey;
    font-style: normal;
    font-size: 1rem;
}

#err-boundary-btn{
    border: 0;
    background-color: var(--accent-color);
    margin-top: 2%;
    width: 10rem;
    height: 3rem;
    color: white;
}

.err-boundary-fallback {
   display: flex;
   font-size: inherit; 
}
.filter-container-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* background-color: aqua; */
}

.floating-btn{
    position: fixed;
    z-index: 999;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    right: 2vw;
    top: 20vh;
}
.error-input-message {
    color: rgb(255, 77, 77);
    font-size: small;
}
.input-error {
    border-color: red!important;
    border-width: 1px!important;
}
.form-input-container {
    margin-bottom: 12px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.side_bar{
  position: fixed;
  /* display: block; */
  top: 0;
  bottom: 0;
  left: 0;
  width: 7rem;
  
  overflow-x: scroll;
  z-index: 1051;
  /* z-index: 1; */
  color: #fff;
  /* background-color: #fff; */
  font-weight: 200;
  background-size: cover;
  transition: 0.15s;
  /* background-position: 50%; */
}

.logoFlux {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 60px;
  margin-left: 1.5%;
  margin-bottom: 1.5%;
}

.main_panel_sb {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  margin: 0;
  width: calc(100vw - 7rem);
  margin-left: 7rem;
  /* display: flex; */

  transition: 0.15s;
}

.bottom_bar {
  display: none;
  z-index: 1051;
}

.logoBottom {
  margin-left: 5%;
  height: 40px;
  width: 40px;
}

@media (max-width: 1200px) {

  .side_bar {
    width: 7rem;
    z-index: 1051;
  }

}

@media (max-width: 700px) {


  .side_bar {
    display: none;
  }

  .bottom_bar {
    display: block;
    position: fixed;
    bottom: 0;

    z-index: 1051;

    align-items: center;
    width: 100%;

    background-color: white;
    height: 40px;
  }
}

#label_menu {
  font-size: small;
  text-align: left;
}

#navbar {
  background-color: var(--border-color);
  display: flex;
  top: 0;

  z-index: 1051;
  width: 100vw;
  height: 8%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  margin-right: 5%;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  #navbarOptions {
    width: 70vw;
  }
}

/* Navbar links */
#navbar a {
  color: rgb(71, 71, 71);
  float: left;
  display: block;
  text-align: center;

  padding: 14px;
  padding-bottom: 3%;
  font-weight: bold;
  box-sizing: border-box;
  text-decoration: none;
}

#navbarExternalDiv {
  display: flex;
  flex-direction: column;
}

#navbarButton {
  background-color: white;
  border-width: 0;
  outline-width: 0px;
}

#navbar a:last-child {
  background-color: var(--border-color);
  float: right;
}

#navbar a:hover {
  color: var(--font2-color);
  background-color: rgb(221, 221, 221);
}

.menu {
  cursor: pointer;
}

.item:hover {
  background-color: lightgrey;
}

.Header_Logo {
  height: 30px;
  margin-left: 30px;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  min-width: 70px;
  min-height: 50px;
  padding: 1%;
}

.Header_Logo_sm_sb {
  /* height: 30px; */
  display: flex;
  flex: 1 1;
  max-width: 7rem;
  padding: 10px;
}

.header_centralize {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  background-color: blue;
}

.no_margin {
  margin: 0;
}

.no_padding {
  padding: 0;
}

.principalHeaderText {
  text-transform: unset !important;
}

.infinity-container{
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
}
.loading{
    /* height: 100vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-content-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: black; */
    margin-bottom: 10%;
}

.icon-no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10%;
    /* background-color: white; */
}

.no-content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}
.no-reports-container {
  margin: 10%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
}

@media (max-width: 700px) {
  .no-reports-container {
    margin: 5%;
  }
}

/* obc - organization by city */

.obc-container {
    width: 100%;
}

.ui.selection.dropdown {
    border-radius: 0rem !important;
    min-height: 0rem !important;
}


.button:before {
    display: none!important;
}

.container-dropdown {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1%;
    /* padding-left: 2.5%; */
    /* padding-right: 2.5%; */
    /* background-color: red; */
}
.obp-container {
    width: 100%;
}
#obp-cep-input {
    font-size: 1rem;
}
#error-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
#error-page-message{
    display: flex;
}
#container-header {
    display: flex;
    flex-direction: row;
    padding: 1%;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border:  1px solid whitesmoke;
}

.page-title {
    align-items: center;
    padding: 0;
    margin:0;
    font-size: 1.8rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}
#container-header {
    display: flex;
    flex-direction: row;
    padding: 1%;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border:  1px solid whitesmoke;
}

.page-title {
    align-items: center;
    padding: 0;
    margin:0;
    font-size: 1.8rem;
    flex: 1 1;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.page-subtitle {
    flex: 1 1;
    font-size: 1.6rem;
}
.pagemask-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  background-image: url(../../static/media/login_background.892c4479.jpg);
  background-color: rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

.pagemask-toolbar {
  justify-content: space-between;
}

.pagemask-container-fluxmed {
  background-image: url(../../static/media/login_background.45068bb1.jpg);
}

.pagemask-content {
  width: calc(100% - 4%);
  margin: 3%;
  padding: 1%;
  box-sizing: border-box;
  /* border: 1px solid red; */
  min-height: 70vh;
  background: white;
}

.pagemask-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refresh-token-input {
  width: 100%;
  border: #000 solid 0.1em;
  border-radius: 0.2em;
  margin-bottom: 1em;
  padding: 0.2em;
}

.dropdowntranslate-title {
    display: block;
    text-align: right;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 0.4rem;
}
.pagination-centralize {
    background-color: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalPDFContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.modalPDFContent div {
    height: 92%;
}
.DeployVersion_HeaderBottomLateral {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 2%;
  display: flex;
  justify-content: center;
  color: black;
}

.DeployVersion_Login {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-right: 2%;
  display: flex;
  justify-content: right;
}

.radio-buttons-container {
  display: flex;
  flex-direction: row;
  /* background-color: aliceblue; */
  align-items: center;
  justify-content: space-between;
  padding-top: 5%;
  /* width: 100%; */
  /* height: 100%; */
}

.schedulerContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.schedulerContent .schedulerBody {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  margin-top: 40px;
}

 .schedulerContent .schedulerBody .schedulerButton {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: none;
  padding: none;
  height: 500px;
  /* height: fit-content; */
  /* border: 1px solid red; */
}
 
.weekDayContent .card {
  /* border: 1px solid red; */
  overflow: scroll;
  padding: none;
  margin: none;
  display: flex;
  height: 500px;
}
  
.weekDayContent .timeButtons {
  flex-direction: column;
}
.ui.search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.input {
    width: 80%;
}
.ui.search {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.input {
    width: 80%;
}

.ui.search>.results {
    width: 80% !important;
    left: 10%;
    right: 10%;
}
.searchContent-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    /* padding: 0% 5%; */
}

#searchBox {
    display: flex;
    /* width: 80vw; */
    /* background-color: blue; */
}

.tableCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#btn-tab-nav {
    display: flex;
    justify-content: right;
    width: 100%;
    /* padding-left: 53%; */
    margin-bottom: 1%;
}

.each-btn {
    width: 10%;
    /* display: flex; */
    /* justify-content: right; */
    /* margin-left: 2%; */
    /* padding-left: 20%; */
}
.inv-btn {
    width: 10%;
    opacity: 0!important;
    /* display: flex; */
    /* justify-content: right; */
    /* margin-left: 2%; */
    /* padding-left: 20%; */
}

.tav-nav-entry {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tav-nav-entry-icon{
    display: flex;
    height: 5rem;
    align-items: center;
    justify-content: center;
    /* width: 80%; */
}

.tav-nav-entry-icon-left{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    margin-right: 2%;
    /* width: 80%; */
}
.toggle-eye-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: blue; */
    /* padding: 2%; */
    width: 100%;
}

.toggle-eye-row {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2% 0% 2% 0%;
    width: 100%;
}



.toggle-eye-bellow-deck {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    transition: 0.3;
    /* color: white; */
    /* background-color: whitesmoke; */
    /* background-color:#6c757d; */
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-right: 30%;
    margin: 0;
    text-align: justify;
    padding: 1% 0 1% 0;
    font-size: 1.2rem;

}


.header-togglebtn {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: blue; */
    /* padding: 2%; */
    width: 100%;
}

.header-row {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2% 0% 2% 0%;
    width: 100%;
}


.showchild {
    display: flex;
    justify-content: space-around;
    margin-left: 5%;
    margin-right: 5;
    /* background-color: red; */
}

.showchild:last-of-type {
    margin-left: 10%;
    margin-right: 5%;

    /* background-color: blue; */
}



.bellow-deck {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    transition: 0.3;
    /* color: white; */
    background-color: whitesmoke;
    /* background-color:#6c757d; */
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-right: 30%;
    margin: 0;
    text-align: justify;
    padding: 1% 0 1% 0;
    font-size: 1.2rem;

}

.deck-left{
    display: flex;
    flex-direction: column; 

}


.show-green {
    color: var(--accent-color);
}

.showname-btn-name {
    
    /* justify-content: center; */
    display: flex;
    opacity: 1;
    /* padding-left: 25%; */
    /* padding-right: 20%; */

    text-align: justify;

    display: flex;
    flex-direction: row;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.showname-btn-text-none {
    margin-left: 1%;
    margin-right: 1%;
    /* background-color: green; */

    display: none;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.2rem;
    
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}
.newpagemask-section {
    padding: 2rem 5rem 2rem;
    border-bottom: 1px solid #bbb;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.newpagemask-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.newpagemask-welcome {
    font-size: 1.8rem;
    color: #08824A;
    margin-left: 2rem;
}

.newpagemask-dropdowns {
    display: flex;    
}

.dropdownorganization-title {
    display: block;
    text-align: right;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 0.4rem;
}

.customtab-underline {
    border-bottom: 4px solid #08824A;;
    width: -webkit-fit-content;;
    width: -moz-fit-content;;
    width: fit-content;
    display: flex;
    padding-bottom: 0.4rem;
    padding-left: 0.5rem;
}

.customtab-title {
    align-self: flex-end;
    margin-bottom: 3px;
    margin-left: 4rem;
    margin-right: 6rem;
    color: #08824A;;
}

.customtab-section {
    padding: 2rem;
}
.RecoverPass_appContainer {
    padding: 0%;
    padding-bottom: 0;
    align-content: center;
    background-image: url(../../static/media/login_background.892c4479.jpg);
    background-color: 'black';
    background-size: auto;
    background-attachment: fixed;
    height: 100vh;
    /* width: 90vw; */
    width: inherit;
    /* box-sizing: border-box; */
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    box-sizing: border-box;

    overflow: hidden;

    
    min-width: 300px;
    min-height: 640px;
  }

  .RecoverPass_dataContainer {
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
    /* padding-top: 20%; */
    padding: 10%;

    align-items: center;
    align-self: center;
    text-align: center;
    align-content: center;
    box-sizing: border-box;



    background-color: rgba(255,255,255,0.7);
    border: 2px rgba(255,255,255,0.5);

    
    /* min-width: 300px; */
    /* min-height: 640px; */
  }

  .RecoverPass_col {
    display: flex;
    flex-direction: column;
  }

  .RecoverPass_inputContainer {
    opacity: 1;

    margin-top: 2%;
    align-content: center;
    align-self: center;
    align-content: center;
    text-align: center;
    box-sizing: border-box;
    
    /* border: 1; */
    height: 3.5vw;
     /* width: 60%; */ 
    /* color: var(--font2-color); */
    /* font-size: 1.4rem; */
    font-weight: bold;
    width: 100%;
    text-align: center;
    /* border-style: solid; */
    /* border-color: gray; */
    /* border-radius: 40px; */
    /* background-color: var(--border-color); */
  }

  .RecoverPass_Button {
    font-size: 2rem;
    /* display: inline-block; */
    margin: 4%;
    margin-bottom: 2%;
    padding: 3%;
    text-align: center;
    align-content: center;
    align-self: center;
    align-content: center;
    box-sizing: border-box;
    
    border: 0;
    color: white;
    outline: 0;
    height: 30%;
    min-height: 50px;
    width: 50%;
  }

  
  .RecoverPass_errorMsg {
    font-size: clamp(14px, 1.6vw, 24px);
    margin:5%;
    color: red;
  }

  
  .RecoverPass_successMsg {
    font-size: clamp(14px, 1.6vw, 24px);
    margin:5%;
     color: var(--font2-color);
  }
  
  .RecoverPass_title {
    font-size: clamp(24px, 1.8vw, 36px);
    margin:5%;
     color: var(--font2-color);
  }
  
  .RecoverPass_text {
    font-size: clamp(14px, 1.4vw, 24px);
    margin:5%;
    color: rgb(66, 66, 66) 59, 58);
  }

  .RecoverPass_callback {
    text-align: center;
    align-content: center;
    align-self: center;
    align-content: center;
    box-sizing: border-box;
  }

.loading-scrn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  z-index: 10;
  max-height: -webkit-max-content;
  max-height: max-content;
}

.Login_inputContainerData {
  margin-bottom: 2%;
  width: 45%;
}

.Login_inputContainerData div p {
  margin-top: 0;
  margin-bottom: -20px;
  padding: 0;
}

.Login_Button {
  width: 50%;
  height: 4rem;
}

.App {
  margin: 0;
  padding: 0%;
  display: flex;
}

#allTheWidth {
  margin: 0%;
  padding: 0%;
  width: 100vw;
  height: 100%;
}

.react-icons {
  vertical-align: middle;
}

.Login_Gradient {
  height: 100vh;
  margin: 0;
  background-image: url(../../static/media/login_background.892c4479.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 50vw;
  background-size: 100vw;
  background-position: center;
  overflow: hidden;
}
.Login_appContainer {
  padding: 0%;
  padding-left: 0%;
  padding-right: 0%;
  margin: 0%;
  align-content: center;
  background-attachment: fixed;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  min-width: 300px;
  height: 100vh;
  box-sizing: border-box;
}

.Login_Gradient {
  height: 100vh;
  margin: 0;
  background-image: url(../../static/media/login_background.45068bb1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 50vw;
  background-size: 100vw;
  background-position: center;
  overflow: hidden;
}

.Login_dataContainer {
  display: flex;
  padding-top: 3rem;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  min-width: 300px;
  width: 100%;
  height: 100vh;
  min-height: 400px;
}

.fluxmed_Logo {
  margin-top: 10rem;
  width: 85%;
}

.login_GradientProject {
  height: 100vh;
  margin: 0;
  background-image: url(../../static/media/login_background.45068bb1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: local;
  width: 50vw;
  background-size: 100vw;
  background-position: center;
  overflow: hidden;
}

.Login_form {
  font-size: 16px;
}

.Login_formContainer {
  padding-left: 10%;
  padding-right: 5%;
  margin: 2%;
  justify-content: center;
  display: flex;
}

.nopadding {
  padding: 0;
  margin: 0;
}

.Login_dataContainer {
  display: flex;
  padding-top: 3rem;
  justify-content: flex-start;
  background-color: var(--secondary-color);
  min-width: 300px;
  width: 100%;
  height: 100vh;
  min-height: 400px;
}

.Login_logo {
  display: block;
  margin: 40px auto;
  max-height: 150px;
}

.Login_formContainerInp {
  padding-left: 2%;
  padding-right: 5%;
  margin: 2%;
  justify-content: center;
  display: flex;
}

.Login_loginButton {
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;

  border: 0;
  border-radius: 5px;
  background-color: var(--confirm-button-color);
  color: var(--font4-color);
  outline: 0;
  height: 4rem;
  width: 45%;
  min-width: 308px;
  margin-left: 1%;
}

.Login_errorMsg {
  color: var(--warning-color);
  font-family: Arial;
  text-align: center;
}

.Login_buttonArea {
  font-size: 20px;

  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 8em;
  width: 100%;
}

.Login_icons {
  color: var(--icon1-color);
  opacity: 0.8;
  padding-bottom: 2%;
  padding-left: 2%;
  align-content: center;
  align-self: center;
  align-content: center;
  min-width: 10%;
}
.Login_loginButton {
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;

  border: 0;
  border-radius: 5px;
  background-color: var(--confirm-button-color);
  color: var(--font4-color);
  outline: 0;
  height: 4rem;
  width: 30rem;
  margin-left: 1%;
}

.Login_loginButton:hover {
  color: var(--font2-color);
  background-color: var(--other-button-color);
  transition: 0.3s;
}

.Login_regularButton {
  font-size: 16px;
  font-weight: bold;
  opacity: 1;
  text-align: center;
  align-content: center;
  align-self: center;
  align-content: center;
  box-sizing: border-box;

  border: 0;
  background-color: transparent;
  color: var(--font2-color);
  outline: 0;
  width: 75%;
}

.Login_regularButton:hover {
  color: var(--font3-color);
  font-weight: bold;
}

.Login_govBrButton {
  height: 4rem;
  width: 50%;
}

.Login_govBrButton:hover {
  color: var(--font4-color);
  background-color: var(--gov2-bg-button-color);
  transition: 0.3s;
}

.Login_warning {
  background-color: transparent;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  height: 5%;
  border: 0;
  margin: 0;
  color: var(--warning-color);
}

#Login_appQr {
  width: 10vw;
}

.Login_divAppQr {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 99vh;
}

.Login_divAppQrCard {
  top: 88vh;
  position: absolute;
  border-radius: 15px;
  background-color: var(--qr-code-bg-color);
}

.Login_Row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Login_Column {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.container-fluid {
  padding: 0;
}

.Login_logoFlux {
  width: 20%;
  opacity: 1;
  margin-left: 2vh;
  align-self: center;
}

.login_divProject {
  display: flex;
  justify-content: center;
  position: flex;
  bottom: 0;
  margin-top: auto;
  left: -3vh;
}

@media screen and (max-width: 700px) {
  .Login_appContainer {
    height: 100vh;
  }

  .Login_divAppQrCard {
    width: 50vw;
    margin-bottom: 20px;
  }
}

@media (max-height: 700px) and (min-width: 1200px) {
  .Login_dataContainer {
    padding-top: 1rem;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, p, a,
b, u, i, center,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.btn-success {
  color: #fff;
  background-color: #08824a !important;
  background-color: var(--accent-color) !important;
  border-color: #08824a !important;
  border-color: var(--accent-color) !important;
}

.btn {
  border-radius: 0 !important;
}

.btn-link {
  color: #08824a !important;
  color: var(--accent-color) !important;
}

.btn-outline-success {
  color: #08824a !important;
  color: var(--accent-color) !important;
  border-color: #08824a !important;
  border-color: var(--accent-color) !important;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem transparent;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff !important;
  background-color: #08824a !important;
  background-color: var(--accent-color) !important;
}

.btn-outline-success:hover {
  color: white !important;
  background-color: #08824a !important;
  background-color: var(--accent-color) !important;
  border-color: #08824a !important;
  border-color: var(--accent-color) !important;
}

.container {
  max-width: 100vw;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  padding: 0%;
  margin: 0%;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  align-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  outline-width: 0px;
}

.accent-color-secondary {
  color: rgb(52, 42, 99);
  color: var(--accent-color-secondary);
}

.accent-color {
  color: #08824a !important;
  color: var(--accent-color) !important;
}

.accent-color-light {
  color: #b9f1f7;
  color: var(--accent-color-light);
}

:root {
  font-size: 85%;

  /* Colors */
  --border-color: whitesmoke;
  --container-color: rgb(255, 255, 255);
  --timeline-background-color: rgb(255, 255, 255);
  --accent-color: #08824a;
  --accent-color-secondary: rgb(52, 42, 99);
  --accent-color-light: #b9f1f7;
  --secondary-color: rgba(255, 255, 255, 0.664);
  --bg-main-color: rgb(253, 253, 253);

  --cancel-button-color: #820808;
  --confirm-button-color: #08824a;
  --other-button-color: white;
  --disabled-button-color: rgba(172, 172, 172, 0.4);
  --gov-bg-button-color: white;
  --gov2-bg-button-color: #1351b4;
  --gov-border-button-color: #1351b4;

  --searchbar-border-color: lightgray;

  --font1-color: rgb(45, 62, 80);
  /* --font2-color: #8208EE; */
  --font2-color: #08824a;
  --font3-color: #006421;
  --font4-color: white;
  --font5-color: rgb(100, 100, 100);
  --font6-color: #1339b4;

  --warning-color: red;

  --border-container: 3px var(--border-color) solid;
  --border-radius-container: 0;

  --icon1-color: gray;

  --qr-code-bg-color: rgb(212, 212, 212);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

html,
body,
#root {
  height: 100vh;
}

p {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.modal-footer,
.modal-body {
  font-size: 1.2rem;
}

.modal-title {
  font-size: 1.4rem;
}

.ui.input {
  border-radius: 0% !important;
}

.field {
  /* background-color: blue; */
  /* padding: 0%!important; */
  margin: 0% !important;
}

.ui.input {
  width: 100%;
  padding: 0% !important;
  margin-left: 0% !important;
  margin-right: 0% !important;

  margin-bottom: 2%;
}

.dropdown-gen {
  color: #08824a;
}

body,
input,
button,
textarea {
  font: 500 1.4rem sans-serif;
  border-radius: 0% !important;
}

input[type='date']:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}
input[type='date']:focus:before,
input[type='date']:valid:before {
  content: '';
}

@media (max-width: 700px) {
  :root {
    font-size: 65%;
  }
}

label {
  font-size: 1.1rem;
  margin: 0;
}

.pages-container {
  width: calc(100vw - 7rem);
  /* border: 4px solid red; */
  margin-left: 7rem;
  height: 100vh;
}

@media (max-width: 700px) {
  .pages-container {
    max-width: 100vw;
    width: 100vw;
    height: calc(100vh - 40px);
    /* border: 4px solid blue; */
    margin-left: 0;
  }
}


.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: 70%;
    /* max-height: 70vh; */
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }

  .button-accept{
    color: #fff !important;
    background-color: #08824ADD !important;
  }
  .button-decline{
    color: #fff !important;
    background-color: #f50057 !important;
  }
  .button-disabled{
    background-color: #e0e0e0;
    cursor: auto !important;
  }
.newPatientFoundInfoAndAccess-acess table {
    width: unset !important;
    margin: 0px auto !important;
}

.newPatientFoundInfoAndAccess-acess table th {
    text-align: center !important;
}

.newPatientFoundInfoAndAccess-acess table td {
    padding: 0.6rem !important;
}

.newPatientFoundInfoAndAccess-acess {
    margin-top: 3rem !important;
    margin-bottom: 3rem;
    width: 80% !important;
}
.newPatientList-totalPatients {
    color: #08824A;
    margin-bottom: 1.5rem;
}

.newPatientList-container tr > td:last-of-type {
    width: 1rem;
    padding-left: 0.5rem;
 }

.newPatientList-container {
    margin-bottom: 3rem;
}
.newSearchPatient-controlOptions {
    margin-top: 2.5vw;
    display: flex;
    justify-content: space-between;
}

.newSearchPatient-advancedSwitch {
    float: left;
}

.newSearchPatient-searchButton {
    float: right;
}

.newSearchPatient-section {
    padding: 2rem 5rem 2rem;
    border-bottom: 1px solid #bbb;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.newSearchPatient-fullHeight {
    min-height: calc(100% - 300px);
    border-bottom: 0;
}

.newSearchPatient-switchFieldname {
    font-size: 1.2vw;
}

.newSearchField-container .MuiInput-underline::before {
    /* border-bottom: none; */
    border: none;
    content: none;
}

.newSearchField-container .MuiInput-underline::after {
    content: none;
}

.newSearchField-container {
    width: 100%;
}

.newSearchField-searchbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.5vw;
    max-height: 54px;
    margin: 1%;
    margin-left: auto;
    margin-right: auto;
    font-size: clamp(14px, 1.6vw, 28px);
    border-radius: 5px;
    padding-left: 1vw;
    padding-right: 1vw;
    /* border-radius: 40px; */
    border-style: solid;
    border-width: 1px;
    border-color: rgb(212, 212, 212);
    background-color: white;
    min-height: 30px;
    min-width: 350px;
}

.newSearchField-fieldname {
    font-size: 1.2rem;
}

.newSearchPatient-container .dropdownorganization-title {
    text-align: left;
    font-size: 1.2rem;
    font-weight: normal;
}

.dropdownorganization, .MuiFormControl-root, .MuiTextField-root {
    width: 100%;
}

.newSearchPatient-birthdateField {
    display: inline-block;
}

.MuiInput-underline::after {
    border-bottom: 2px solid #08824A !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: #08824A !important;
  }

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #08824A !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #08824A !important;
}

.newSearchPatient-outer table, th, td {
    border: 1px solid #bbb;
    padding: 0.5rem;
    padding-left: 1rem;
}

.newSearchPatient-outer table {
    width: 80%;
    font-size: 1rem;
}

.newSearchPatient-outer th {
    font-weight: bold;
}

.MuiPickersDay-daySelected {
    background-color: #08824A !important;
}
.newConsentsPage-section {
    padding: 2rem 5rem 2rem;
    border-bottom: 1px solid #bbb;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.newConsentsPage-section table, th, td {
    border: 1px solid #bbb;
    padding: 0.5rem;
    padding-left: 1rem;
}

.newConsentsPage-section table {
    width: 80%;
    font-size: 1rem;
}

.newConsentsPage-section th {
    font-weight: bold;
}


.newConsentsPage-section tr > td:last-of-type {
    width: 1rem;
    padding-left: 0.5rem;
 }

 .newConsentsPage-table {
    margin-top: 1rem;
 }

.summaryTab-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0.25% 0 0.25% 0;
    padding: 0 3% 0 3%;
}

.summaryTab-container .summaryTab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.summaryTab-container .MuiTableRow-hover:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--accent-color);
}

.summaryTab-container .MuiTableRow-hover:hover .MuiTableCell-root {
    color: var(--accent-color);
}

.summaryTab-container  .MuiTableCell-root.observationTabContent-document:hover {
    color: var(--accent-color);
    cursor: pointer;
    text-decoration: underline;
}
.patientSummary-container {
    /* border: 2px solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin: 2% 0 2%;
    font-family: Arial;
    background-color: rgba(0,0,0,0.02);
}

.patientSummary-container .patientSummary-header {
    margin: 1% auto 1% auto;
    padding:  1% 2% 1% 2%;
    /* border: 0.5rem solid rgba(0, 0, 0, 0.2); */
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}
div::-webkit-scrollbar {
  display: none;
}

.Button:hover {
  background-color: rgb(24, 68, 35);
}

.Timeline:hover {
  background-color: rgb(148, 148, 148);
  color: white;
}

.Index_Button:hover {
  background-color: white;
  color: #08824a;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

#Visualizer_level4Label {
  border: 0;
  /* background-color: black; */
  /* border-radius: 2%; */

  padding: 2%;
  font-size: 15;
  font-weight: bold;
  margin-top: 10px;
  /* margin-bottom: 4; */
  width: 100%;
  box-sizing: border-box;
}

#Visualizer_detail_container {
  background-color: var(--border-color);
  padding: 0;
  /* margin-top: 4%; */
  color: var(--font2-color);
  /* height: 3vw; */
}

#Visualizer_detail {
  /* display: none; */
  height: 0vw;
  /* background-color: rgb(4, 47, 27); */
  color: white;
  opacity: 0;

  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.modal-90w {
  width: 90vw;
}

.Visualizer_header {
  /* overflow: hidden; */

  box-sizing: border-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;

  padding-top: 0.5%;
  background-color: #ffffff;
  width: 100%;
  /* height: 20vw; */
  /* max-height: 120px; */
  padding-bottom: 0.5%;
  /* margin-bottom: 1%;     */
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.Visualizer_sidebar {
  height: 900px;
  /* width: 32vw; */

  /* overflow-y: scroll; */
  opacity: 0.8;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;
  /* margin-left: 5%; */
  /* padding-left: 1%; */
  /* padding-right: 1%; */
  /* margin-right: 5%; */
  /* border-radius: 5px; */
  /* position: 'fixed'; */
  /* background-color: var(--accent-color-secondary); */
  width: 100%;
  background-color: rgb(235, 235, 235);
  min-width: 180px;
  /* background-color:rgb(191, 236, 215); */
}

.Visualizer_DocTypeButton {
  box-sizing: border-box;
  background-color: white;
  height: 7vw;
  width: 100%;
  padding: 2%;
  /* color:rgb(108, 202, 158); */
  color: #08824a;
  font-size: 1.5vw;
  border: 1;
  vertical-align: center;
  border-width: 5px;
  border-color: red;
  cursor: pointer;
}

.Visualizer_DocTypeButton:hover {
  background-color: rgb(8, 131, 74);
  color: white;
}

.Visualizer_DocTypeButton:focus {
  transition: 0.3s;
  background-color: rgb(7, 109, 62);
  color: white;
}

.Visualizer_DocTypeButton:active {
  transition: 0.3s;
  background-color: rgb(7, 109, 62);
  /* background-color: rgb(3, 46, 26); */
  color: white;
}

.Visualizer_row {
  display: flex;
  flex-direction: row;
  /* min-height: 20vh; */
  /* min-width: 90vw; */
  /* flex-wrap: wrap; */
}

.Visualizer_column {
  display: flex;
  flex-direction: column;
}

.Visualizer_documentViewer_patientSumm {
  opacity: 0.9;
  padding-left: 2%;
  padding-right: 2%;
  box-sizing: border-box;
  align-self: center;
  align-content: center;
  align-items: center;
}

.Visualizer_documentViewer {
  opacity: 0.9;
  margin-left: 5%;
  margin-right: 5%;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;

  height: 100%;
  width: 80vw;
  border: 0;
  border-style: solid;
  /* border-radius: 12px; */
}

.Visualizer_documentViewerNotSelected {
  background-color: white;
  opacity: 0.8;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
  height: 80%;
  border: 0;
  border-style: solid;
  /* border-radius: 12px; */
}

.Visualizer_documentViewerError {
  background-color: white;
  opacity: 0.8;
  box-sizing: content-box;
  align-self: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
  height: 80%;
  border: 0;
  border-style: solid;
  /* border-radius: 12px; */
}

/* .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
} */

.VisualizerPage {
  /* background-color: rgba(202, 202, 202, 0.9); */
  background-color: white;
  align-content: start;
  /* border: 2px rgba(255,255,255,0.5); */
  /* border-radius: 12px; */
  /* border: 2px solid red; */
  border-top: 1px solid rgba(202, 202, 202, 0.9);
  box-sizing: border-box;
  height: 100%;
  min-width: 300px;
  min-height: 640px;
  /* padding: 1%; */
  /* padding-top: 2%; */
  width: 100%;
  /* margin-left: 5%; */
  /* margin-right: 5%; */

  /* min-height: 100vh; */
  /* width: inherit; */
}

#Visualizer_modalDoc {
  width: 65vw;
  max-width: none !important;
  min-width: 590px;
}
/* @media screen and (max-width: 600px) {
    #Visualizer_modalDoc {
      width: 90vw;
      max-width: none!important;
    }
  } */
#Visualizer_modalDoc {
  width: 80vw;
}

@media screen and (max-width: 600px) {
  .Visualizer_header {
    padding-top: 20px;
    background-color: #ffffff;
    width: 100%;
    /* height: 20vw; */
    /* max-height: 100px; */
    padding-bottom: 0.5%;
    /* margin-bottom: 1%;     */
    overflow: hidden;
    /* text-overflow: ellipsis; */
  }
}

.loadingDiv {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25vh;
}

.ExamButton {
  margin-right: 5px;
  border: 0px solid #08824ADD;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  padding: 5px;
}

.ExamButton-enabled {
  background-color: #08824ADD !important;
  color: #fff !important;
}

.ExamButton-disabled { 
  background-color: #e0e0e0 !important;
  color: black !important;
}

.ExamButton:hover {
  cursor: pointer;
}

.normal-exam-results {
  width: 100%;
}

.pdf-viwer-enabled {
  display: block;
  width: 50%;
  min-height: 500px;
}
.pdf-viwer-disabled {
  display: none;
}

.close-icon {
  content: 'x';
  cursor: pointer;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
  position: static;
  position: initial;
  float: right;
}

.header-component {
    background-color: var(--accent-color-light);
    width: 100%;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    height: 9%;
  }

  .headerp {
    margin: 0;
    color:var(--accent-color-secondary);
    text-align: left;
    font-size: 1 rem;
  }

  .headerp_text {
    margin: 0;
    color: black;
    text-align: left;
    font-size: 1 rem;
  }
  .patientProfile {
     color:var(--accent-color-secondary);
    font-weight: bold;
    font-size: 22;
  }




.timeline-subject-name {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  width: 100%;
}

.timeline-subject-description {
  font-size: 15px;
  margin-top: 0;
  white-space: nowrap;
}

.reports-container {
  overflow-y: scroll;
  height: 100%;
  background-color: white;
  padding: 2%;
  margin-top: 5%;
}
.year-title {
  background-color: var(--timeline-background-color);
  font-weight: 'bold';
  text-align: 'center';
  padding: '2%';
  color: var(--font1Color);
  margin-bottom: 0;

  width: 100%;
  margin-top: 0;
}

.year-title-full-size-title {
  color: #fff; 
  background-color: #08824ADD;
  margin-left: 8%;
  width: 87.5%;
}

.year-title-middle-size-title {
  color: #fff; 
  background-color: #08824ADD;
  margin-left: 16.5%;
  width: 82.5%;
}

span.MuiTimelineDot-defaultGrey {
  background-color: #08824ADD !important;
  position: absolute !important;
  top: 35% !important;
  left: 7.7% !important;
  z-index: 1 !important;
}
span.MuiTimelineDot-defaultGrey-pdfOpen-ajust {
  background-color: #08824ADD !important;
  position: absolute !important;
  top: 35% !important;
  left: 7.3% !important;
  z-index: 1 !important;
}

p.MuiTypography-colorTextSecondary {
  color: #08824ADD !important;
  position: absolute !important;
  top: 25% !important;
}
p.MuiTypography-colorTextSecondary-pdfOpen-ajust {
  color: #08824ADD !important;
  position: absolute !important;
  top: 25% !important;
  left: 10% !important;
}

span.MuiTimelineConnector-root {
  position: absolute !important;
  left: 8.1% !important;
  height: 190px !important;
  z-index: 0 !important;
}

li.MuiTimelineItem-root {
  border-bottom: none !important;
}
.MuiTimelineOppositeContent-root {
    min-width: 80px;
    max-width: 80px;
}

.timelineDrawer-upperArea .timelineDrawer-header {
    align-items: center;
    font-size: large;
    font-weight: bold;
    box-sizing: border-box;
    /* padding-top: 3%; */
    background-color: rgb(235, 235, 235);
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.infinity-container {
    background: none !important;
}

.timelineDrawer-upperArea {
    text-align: center;
    /* margin-bottom: 1%; */
}

.timelineDrawer-upperArea .timelineDrawer-filter {
    margin-bottom: 1%;
}

.timelineDrawer-upperArea .timelineDrawer-header button {
    position: absolute;
    right: 10px;
}
  
.ArchetypeMenu_container {
  /* border-width: 2px; */
  /* border-color: rgb(180, 180, 180); */
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: large;
  font-weight: bold;
  box-sizing: border-box;
  padding-top: 3%;
  height: 4vw; */
  background-color:rgb(8, 130, 73);
  /* background-color:rgb(8, 130, 73); */
  /* color:rgb(77, 78, 78); */
  opacity: 0.9;
  border-radius: 30px;
  padding: '1%';
}
.ArchetypeMenu_button {
  opacity: 1;
  /* border-width: 2px; */
  /* border-color: rgb(180, 180, 180); */
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: large;
  font-weight: bold;
  /* padding-top: 3%; */
  height: 6vw;
  background-color:#08824A;
  border-radius: 30px;
  margin: 1%;
  /* padding: 3%; */
  flex: 1 1;
  box-sizing: border-box;
  border-color: white;
}

.ArchetypeButton_text {
  color: white;
  text-align: center;
  vertical-align: center;
  font-size: 1.2vw;
  padding: 5%;
  height: 100%;
  box-sizing: border-box;
}
.ArchetypeButton_text:hover {
  color: var(--accent-color-secondary);
}

.tab {
  width: 100%;
  padding: 25px;
  font-family: sans-serif;
  color: #444;
}

ul.inline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

ul.inline,
li {
  display: inline-block;
  margin-left: 0;
  padding: 10px;
  border-bottom: 2px solid #eee;
  transition: all 0.5s;
  font-family: Lato, sans-serif;
  font-weight: 300;
  cursor: pointer;
  /* color: #aaa; */
}

ul.inline,
li.selected {
  border-bottom: 2px solid #08824ADD;
  color: #444;
}
.profile-conta-area {
    display: flex;
    justify-content: space-between;
}

p {
    padding-top: 0.7rem!important;
    padding-bottom: 0.7rem!important;
}

.profile-edit-button {
    margin-bottom: 2%;
    text-align: center;
    align-content: center;
    
    border: 0;
    border-radius: 5px;
    background-color: var(--accent-color-secondary);
    color: white;
    outline: 0;
    height: 30%;
    min-height: 40px;
    min-width: 180px;
}

.profile-edit-button:hover {
    color: var(--font2-color);
    background-color: var(--border-color);
    height: 33%;
    transition: 0.3s;
}

.profile-password-button {
    margin-bottom: 2%;
    text-align: center;
    align-content: center;
    border: 0;
    border-radius: 5px;
    background-color: var(--accent-color-secondary);
    color: white;
    outline: 0;
    height: 30%;
    min-height: 40px;
    width: 20%;

}

.profile-centered-buttons {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}




.profile-box {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
}

.profile-h1 {
     color: var(--font2-color);
    font-size: 2rem;
}
.profile-title {
    font-weight: 400;
    flex: 1 1;
    color:black;
    font-size: 1.4rem;
    /* padding-bottom: 0; */
}

.profile-info {
    
    font-weight: 400;
    flex: 1 1;
    font-size: 1.4rem;
    color: black;
}

.profile-info-edit {
    flex: 1 1;
    font-size: 1.4rem;
     color: var(--font2-color);
    background-color: var(--border-color);
    border-radius: 30px;
    text-align: center;
    border: 0;

}

.profile-content {
    padding: 2%;
    padding-left: 10%;
    padding-right: 10%;
    /* max-height: fit-content; */
    width: 80vw;
    
    /* overflow:auto; */
    
    background-color: var(--container-color);
    border: var(--border-container);
    /* border-radius: 12px; */
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    min-height:-webkit-fit-content;
    min-height:-moz-fit-content;
    min-height:fit-content;
    /* flex-grow: 1; */
}

.profile-app-container {
    display: flex;
    justify-content: center;
    padding: 1%;
    padding-bottom: 2%;
    align-content: center;
    background-image: url(../../static/media/login_background.892c4479.jpg);
    
    background-size: cover;
    background-attachment: fixed;
    /* height: 100vh; */
    /* width: auto; */
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-position: center; /* Center the image */
    
  }

  .profilePage-container {
      width: 100%;
      padding: 2%;
  }

  @media(max-width: 700px) {
      .profile-content {
          padding: 5%;
      }
  }

  .profilePage-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
  }
.consentsPage-container {
    width: 100%;
    padding: 2%;
    min-height: 90vh;
}

.consent-line{
    background-color: white;
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    /* padding-left: 5%;
    padding-right: 5%; */
}

.consent-col {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 0 2rem 0 2rem;

}

@media (max-width: 950px) {
    .consent-col {
        margin: 0 1rem 0 1rem;
    }
}



ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

.consentSearchBox {
    
    align-content: center;
    align-self: center;
    box-sizing: border-box;
    
    font-size: 1.2rem;
    width: 60vw;
    height: 3.5vw;
    text-align: center;
    border-radius: 0.8rem;
    border-style: solid;
    border-width: 1px;
    border-color: var(--searchbar-border-color);
    min-height: 3rem;
    margin-top: 2%;
    margin-bottom: 3%;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list {
    list-style-type:none;
    margin-left: 0;
}

.consentItemsContainer {
    width: 100%;
    display: flex;
    padding: 0;
    justify-content: center;
}

.consentCategory {
    color: var(--font2-color);
    font-size: 1.6rem;
    padding-bottom: 2%;
    font-weight: bold;
    text-align: center;
}

#consentModalConfirmButton {
    background-color: var(--cancel-button-color);
    border-color: var(--cancel-button-color);
}

#consentModalConfirmButtonGive {
    background-color: var(--confirm-button-color);
    border-color: var(--confirm-button-color);
}

.consentColumn {
    display: flex;
    padding-left: 0;
    justify-content: space-around;
    /* align-self: center; */
    overflow: hidden;
    width: 100%;
}

.consentGiveButtonDiv {
    display: flex;
    align-self: flex-end;
    box-sizing: border-box;
    margin-right: 2vw;
}

.containers {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    padding-left: 2%;
    padding-top: 2%;
    justify-content: center;
}

.human-entry-input {
    display: flex;
    padding: 1%;
    justify-content: center;
    margin: 1%;
}

.state-city-drop {
    display: flex;
    padding: 1%;
    justify-content: space-between;
    margin: 1%;
}

.container-main-info {
    
    /* margin-left: 2%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* padding: 1%; */
    /* margin: 2%; */
    /* background-color: white; */
}


.container-side-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* padding: 1%; */

    /* max-width: 100%; */
    /* height: fit-content; */
    background-color: white;
    /* border:  1px solid whitesmoke; */
}

.consent-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    font-size: 1.6rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.consent-modal-text {
    display: flex;
    justify-content: center;
    text-align: justify;
    font-size: 1.4rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    
}

.container-hidden {
    display: none;
}

.consent-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}



.consent-new-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
}
.consent-access-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* writing-mode: vertical-rl; */
    /* text-orientation: mixed; */
    padding: 0;
    margin-left: 0;
}

.lateral-button{
    margin-left: 4%;
    margin-top: 1%;
    display: flex;
}

#history-title {
    font-size: 1.8rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    margin-bottom: 2%;
    margin-top: 2%;
}


#consentGiveButton{
    display: inherit;
    width: 4vw;
    height: 4vw;
    min-width: 30px;
    min-height: 30px;
    color: var(--accent-color);
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border-style: none;
    border-width: 0px;
    background-color: var(--other-button-color);
}

#consentGiveButton:hover {
    background-color: var(--confirm-button-color);
    color: var(--font4-color);
    transition: 0.3s;
    width: 5vw;
    height: 5vw;
}

.consentGiveConsentInput{
    width: 100%;
    height: 3rem;
    display: flex;
    /* border: solid; */
    /* border-color: var(--searchbar-border-color); */
    border: 0;
    text-align: center;
}

@media(max-width: 700px) {
    .consentDataContainer{
        padding-left: 0;
        padding-right: 0;
        width: 94%;
    }
    
    .consentPage {
        padding: 1%;
    }
}

@media(max-width: 1000px) {
    .consentDataContainer{
        width: 88%;
    }
}

.consentsPage-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
}

/* New ConsentsPage */

.consents-tab {
    padding: 0 !important;

    .consents-table-title {
        h3 {
            text-decoration: none;
        }

        &:hover {
            h3 {
                text-decoration: underline;
            }
        }

        i {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
            transition: .2s;
        }
    }
    .Mui-expanded {
        i {
            -webkit-transform: rotate(0);
                    transform: rotate(0);
            transition: .2s;
        }
    }
}

.consentsPage-container .MuiTableRow-hover {
    cursor: pointer;
}

.consents-accordion {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.back-btn, .cancel-btn {
    border: none;
    background: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: .2s;
}

.cancel-btn:hover {
    opacity: 0.7;
}

.back-btn:hover {
    color: #08824A;
    transition: .2s;
}

.search-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 32px !important;
    opacity: .5;
    transition: .3s !important;
}

.search .input input:focus + .search-icon {
    opacity: 1;
    transition: .3s !important;
}
/* .Policy_ContaArea {
    display: flex;
    flex-direction: row;
} */

.policy-terms-title {
    font-size: 1.6rem;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.policy-terms-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5%;
}

.policy-terms-text {
  display: flex;
  justify-content: center;
  text-align: justify;
  font-size: 1.1rem;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}

.Policy_card {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 0;
    width: 100%;
    padding: 2rem;
    padding-left: 10%;
    padding-right: 10%;
    align-content: center;
    margin-bottom: 3%;
    align-self: center;
    font-weight: bold;
    background-color: var(--disabled-button-color);
}

.Policy_card:hover {
    opacity: 0.85;
    transition: 0.3s;
}

.Policy_card_info {
    flex: 1 1;
    font-weight: 200;
    font-size: 1.2rem;
    width: 100%;
    box-sizing: content-box;
    text-align: justify;
    padding-left: 10%;
    padding-right: 10%;
    color: var(--font5-color)
}

.accordion-terms {
    margin-top: 8%;
}

.Policy_title {
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-weight: 500;
    flex: 1 1;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    color: rgb(78, 78, 78);
    padding-bottom: 0;
}

.Policy_card_chosen {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 0;
    width: 100%;
    padding: 2rem;
    padding-left: 10%;
    padding-right: 10%;
    align-content: center;
    margin-bottom: 3%;
    align-self: center;
    font-weight: bold;
    background-color: var(--accent-color);
}

.Policy_card_chosen:hover {
    opacity: 0.85;
    transition: 0.3s;
}

.Policy_card_info_chosen {
    flex: 1 1;
    font-weight: 200;
    font-size: 1.2rem;
    box-sizing: content-box;
    text-align: justify;
    margin-left: 15%;
    margin-right: 15%;
    color: white;
}

.Policy_title_chosen {
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    flex: 1 1;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: center;
    color: white;
    padding-bottom: 0;
    box-sizing: border-box;
}

.Policy_h1 {
    /* color: rgb(0, 0, 0); */
    color: var(--font2-color);
    /* font-size: 30px; */
    font-size: 2.3rem;
    text-align: center;
    margin-bottom: 5%;
    box-sizing: content-box;
}

.Policy_info {
    flex: 1 1;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    font-size: 1.4rem;
    box-sizing: content-box;
    text-align: justify;
    margin-left: 0%;
    margin-right: 0%;
    color: rgb(0, 0, 0);
}

.Policy_content {
    padding: 2%;
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 2%;
    width: 80vw;
    background-color: var(--container-color);
    border: var(--border-container);
    border-radius: var(--border-radius-container);
}

.Policy_appContainer {
    display: flex;
    padding: 1%;
    padding-bottom: 0;
    align-content: center;
    background-image: url(../../static/media/login_background.892c4479.jpg);
    background-color: 'black';
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
}

@media(max-width: 700px) {
    .Policy_content {
        padding-left: 12%;
        padding-right: 12%;
        padding: 12%;
    }
    .Policy_card {
        margin-top: 10%;
        padding-right: 4%;
        padding-left: 4%;
    }
    .Policy_card_chosen {
        margin-top: 10%;
        padding-right: 4%;
        padding-left: 4%;
    }
}

.policyPage-container {
    width: 100%;
    padding: 8%;
    min-height: 90vh;
}

.policyPage-Loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25vh;
}
.noMatch-container {
  min-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.noMatch-container .noMatch-logo {
opacity: 1;
margin: 5vw auto 1vw auto;
max-height: 25vh;
}

.noMatch-container .noMatch-link {
  margin: 5vh 0 auto 2vw;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .side_bar{
    position: fixed;
    /* display: block; */
    top: 0;
    bottom: 0;
    left: 0;
    width: 7rem;
    
    border-right: 1px solid #bbb;
    overflow-x: scroll;
    z-index: 1051;
    /* z-index: 1; */
    color: #fff;
    /* background-color: #fff; */
    font-weight: 200;
    background-size: cover;
    transition: 0.15s;
    /* background-position: 50%; */
  }
  
  .logoFlux {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 60px;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
  }
  
  .main_panel_sb {
    background: rgba(203, 203, 210, 0.15);
    position: relative;
    float: right;
    margin: 0;
    width: calc(100vw - 7rem);
    margin-left: 7rem;
    /* display: flex; */
  
    transition: 0.15s;
  }
  
  .bottom_bar {
    display: none;
    z-index: 1051;
  }
  
  .logoBottom {
    margin-left: 5%;
    height: 40px;
    width: 40px;
  }

  .ui.icon.menu .item {
    color: #48494B;
  }

  .ui.icon.menu .item:hover {
    color: #08824A;
  }

  .ui.icon.menu .active.item {
    color: #08824A;
  }

  .ui.secondary.menu .active.item {
    background: none;
  }


  @media (max-width: 1200px) {
  
    .side_bar {
      width: 7rem;
      z-index: 1051;
    }
  
  }
  
  @media (max-width: 700px) {
  
  
    .side_bar {
      display: none;
    }
  
    .bottom_bar {
      display: block;
      position: fixed;
      bottom: 0;
  
      z-index: 1051;
  
      align-items: center;
      width: 100%;
  
      background-color: white;
      height: 40px;
    }
  }
  
#label_menu {
  font-size: small;
  text-align: left;
}

#navbar {
  background-color: var(--border-color);
  display: flex;
  top: 0;

  z-index: 1051;
  width: 100vw;
  height: 8%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  margin-right: 5%;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  #navbarOptions {
    width: 70vw;
  }
}

/* Navbar links */
#navbar a {
  color: rgb(71, 71, 71);
  float: left;
  display: block;
  text-align: center;

  padding: 14px;
  padding-bottom: 3%;
  font-weight: bold;
  box-sizing: border-box;
  text-decoration: none;
}

#navbarExternalDiv {
  display: flex;
  flex-direction: column;
}

#navbarButton {
  background-color: white;
  border-width: 0;
  outline-width: 0px;
}

#navbar a:last-child {
  background-color: var(--border-color);
  float: right;
}

#navbar a:hover {
  color: var(--font2-color);
  background-color: rgb(221, 221, 221);
}

.menu {
  cursor: pointer;
}

.item:hover {
  background-color: lightgrey;
}

.Header_Logo {
  height: 30px;
  margin-left: 30px;
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  min-width: 70px;
  min-height: 50px;
  padding: 1%;
}

.Header_Logo_sm_sb {
  /* height: 30px; */
  display: flex;
  flex: 1 1;
  max-width: 7rem;
  padding: 10px;
}

.header_centralize {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  background-color: blue;
}

.no_margin {
  margin: 0;
}

.no_padding {
  padding: 0;
}

.principalHeaderText {
  text-transform: unset !important;
}

