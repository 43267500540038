.summaryTab-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0.25% 0 0.25% 0;
    padding: 0 3% 0 3%;
}

.summaryTab-container .summaryTab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.summaryTab-container .MuiTableRow-hover:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--accent-color);
}

.summaryTab-container .MuiTableRow-hover:hover .MuiTableCell-root {
    color: var(--accent-color);
}

.summaryTab-container  .MuiTableCell-root.observationTabContent-document:hover {
    color: var(--accent-color);
    cursor: pointer;
    text-decoration: underline;
}