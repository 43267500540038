#btn-tab-nav {
    display: flex;
    justify-content: right;
    width: 100%;
    /* padding-left: 53%; */
    margin-bottom: 1%;
}

.each-btn {
    width: 10%;
    /* display: flex; */
    /* justify-content: right; */
    /* margin-left: 2%; */
    /* padding-left: 20%; */
}
.inv-btn {
    width: 10%;
    opacity: 0!important;
    /* display: flex; */
    /* justify-content: right; */
    /* margin-left: 2%; */
    /* padding-left: 20%; */
}

.tav-nav-entry {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tav-nav-entry-icon{
    display: flex;
    height: 5rem;
    align-items: center;
    justify-content: center;
    /* width: 80%; */
}

.tav-nav-entry-icon-left{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
    margin-right: 2%;
    /* width: 80%; */
}