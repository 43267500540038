.noMatch-container {
  min-height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.noMatch-container .noMatch-logo {
opacity: 1;
margin: 5vw auto 1vw auto;
max-height: 25vh;
}

.noMatch-container .noMatch-link {
  margin: 5vh 0 auto 2vw;
}
