.loading-scrn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  z-index: 10;
  max-height: max-content;
}

.Login_inputContainerData {
  margin-bottom: 2%;
  width: 45%;
}

.Login_inputContainerData div p {
  margin-top: 0;
  margin-bottom: -20px;
  padding: 0;
}

.Login_Button {
  width: 50%;
  height: 4rem;
}
