.toggle-eye-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: blue; */
    /* padding: 2%; */
    width: 100%;
}

.toggle-eye-row {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2% 0% 2% 0%;
    width: 100%;
}



.toggle-eye-bellow-deck {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    transition: 0.3;
    /* color: white; */
    /* background-color: whitesmoke; */
    /* background-color:#6c757d; */
    width: 100%;
    height: fit-content;
    padding-right: 30%;
    margin: 0;
    text-align: justify;
    padding: 1% 0 1% 0;
    font-size: 1.2rem;

}

