.ArchetypeMenu_container {
  /* border-width: 2px; */
  /* border-color: rgb(180, 180, 180); */
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: large;
  font-weight: bold;
  box-sizing: border-box;
  padding-top: 3%;
  height: 4vw; */
  background-color:rgb(8, 130, 73);
  /* background-color:rgb(8, 130, 73); */
  /* color:rgb(77, 78, 78); */
  opacity: 0.9;
  border-radius: 30px;
  padding: '1%';
}
.ArchetypeMenu_button {
  opacity: 1;
  /* border-width: 2px; */
  /* border-color: rgb(180, 180, 180); */
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: large;
  font-weight: bold;
  /* padding-top: 3%; */
  height: 6vw;
  background-color:#08824A;
  border-radius: 30px;
  margin: 1%;
  /* padding: 3%; */
  flex: 1;
  box-sizing: border-box;
  border-color: white;
}

.ArchetypeButton_text {
  color: white;
  text-align: center;
  vertical-align: center;
  font-size: 1.2vw;
  padding: 5%;
  height: 100%;
  box-sizing: border-box;
}
.ArchetypeButton_text:hover {
  color: var(--accent-color-secondary);
}
