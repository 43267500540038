.newPatientFoundInfoAndAccess-acess table {
    width: unset !important;
    margin: 0px auto !important;
}

.newPatientFoundInfoAndAccess-acess table th {
    text-align: center !important;
}

.newPatientFoundInfoAndAccess-acess table td {
    padding: 0.6rem !important;
}

.newPatientFoundInfoAndAccess-acess {
    margin-top: 3rem !important;
    margin-bottom: 3rem;
    width: 80% !important;
}